@use 'functions/color' as func;
@use 'settings/vars';
@use 'mixins/breakpoint';
@use 'sass:color';

@mixin base-state-styles(
  $background-color: func.color(primary),
  $font-color: black,
  $fill-color: white
) {
  user-select: none;
  background-color: $background-color;
  color: func.color(primary, $font-color);

  &:hover {
    background-color: color.adjust($background-color, $lightness: 5%);
  }

  fill: func.color(primary, $fill-color);
}

@mixin status-box-style {
  display: inline-flex;
  align-items: center;
  background: func.color(primary, white);
  border-radius: 17px;
  padding: 5px 14px;

  span {
    color: func.color(primary, black);
    font-weight: vars.$font-weight-bold;

    &::before {
      display: inline-block;
      margin-right: 10px;
    }
  }
}

@mixin icon-workbook-we-history-stroke-color($stroke-color) {
  .icon-dashboard-work-and-education-history,
  .icon-dashboard-workbook {
    stroke: func.color(primary, $stroke-color);
  }
}

@mixin approved-styles {
  &.approved {
    @include base-state-styles(func.color(state, complete), white, white);
    @include icon-workbook-we-history-stroke-color(white);
  }

  &__main-content .state {
    &.approved {
      @include status-box-style;

      &::before {
        border-bottom: 2px solid func.color(state, complete);
        border-right: 2px solid func.color(state, complete);
        content: '';
        height: 14px;
        margin-right: 10px;
        margin-bottom: 5px;
        transform: rotate(45deg);
        width: 7px;
      }
    }
  }
}

@mixin incomplete-styles {
  &.incomplete {
    @include base-state-styles(func.color(state, incomplete), black, black);

    .icon-dashboard-workbook {
      stroke: func.color(primary, white);
    }
  }

  &__main-content .state {
    &.incomplete {
      @include status-box-style;

      span {
        &::before {
          content: '•••';
          color: func.color(state, manage);
        }
      }
    }
  }
}

@mixin default-styles {
  &.default {
    @include base-state-styles(func.color(state, default), white, white);

    .icon-dashboard-workbook {
      stroke: func.color(primary, white);
    }
  }

  &__main-content .state {
    &.default {
      opacity: 0.6;

      span {
        margin-left: 10px;
      }

      & > svg-icon svg {
        bottom: -3px;
      }
    }
  }
}

@mixin pending-styles {
  &.pending {
    @include base-state-styles(func.color(state, pending), black, black);
    @include icon-workbook-we-history-stroke-color(black);
  }

  &__main-content .state {
    &.pending {
      @include status-box-style;

      span {
        &::before {
          content: '•••';
          color: func.color(state, pending);
        }
      }
    }
  }
}

@mixin issue-styles {
  &.issue,
  &.rejected {
    @include base-state-styles(func.color(state, issue), white, white);
    @include icon-workbook-we-history-stroke-color(white);
  }

  &__main-content .state {
    &.issue,
    &.rejected {
      @include status-box-style;

      span {
        &::before {
          content: '';
          position: relative;
          background-image: url('/assets/svg/cross.svg');
          background-size: 10px 10px;
          background-repeat: no-repeat;
          bottom: -1px;
          height: 10px;
          width: 10px;
          margin-right: 9px;
        }
      }
    }
  }
}

/* stylelint-disable selector-class-pattern */
@mixin in-review-styles {
  &.in-review,
  &.in_review {
    @include base-state-styles(func.color(state, review), black, black);
    @include icon-workbook-we-history-stroke-color(black);
  }

  &__main-content .state {
    &.in-review,
    &.in_review {
      @include status-box-style;

      span {
        &::before {
          content: '';
          position: relative;
          background-image: url('/assets/svg/eye.svg');
          background-size: 18px 13px;
          background-repeat: no-repeat;
          bottom: -2px;
          height: 13px;
          width: 18px;
          margin-right: 8px;
        }
      }
    }
  }
}

@mixin call-us-styles {
  &.call-us {
    @include base-state-styles(func.color(state, call-us), black, black);
    @include icon-workbook-we-history-stroke-color(black);
  }

  &__main-content .state {
    &.call-us {
      @include status-box-style;

      span {
        &::before {
          background-image: url('/assets/svg/phone.svg');
          background-size: 15px 13px;
          bottom: -2px;
          content: '';
          height: 13px;
          margin-right: 7px;
          position: relative;
          width: 15px;
        }
      }
    }
  }
}

@mixin manage-styles {
  &.manage {
    @include base-state-styles(func.color(state, manage), black, black);
    @include icon-workbook-we-history-stroke-color(black);
  }

  &__main-content .state {
    &.manage {
      @include status-box-style;

      span {
        &::before {
          content: '•••';
          color: func.color(state, manage);
        }
      }
    }
  }
}

@mixin state-mixins {
  @include approved-styles;
  @include incomplete-styles;
  @include pending-styles;
  @include issue-styles;
  @include in-review-styles;
  @include call-us-styles;
  @include manage-styles;
  @include default-styles;
}

.dashboard-alert {
  margin: 20px;
}

.current-modal-message {
  margin-bottom: 20px;
}

.dashboard {
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 100%;

  @include breakpoint.bp(min-width, vars.$medium-min) {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-right: 0;
  }

  &__header {
    margin-bottom: 30px;
  }

  &__sections {
    &,
    .row {
      margin-right: -8px;
      margin-left: 0;
    }

    & > .col-md-4 {
      padding: 0;
    }

    .section {
      height: 198px;
      margin-bottom: 8px;
      margin-right: 8px;
      display: flex;
      flex-flow: column nowrap;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;

      @include state-mixins;

      &--disabled {
        cursor: default;
      }

      &__main-content {
        height: 100%;
        width: 100%;
        outline: none;
        padding: 20px;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: space-between;

        .title {
          font-size: 18px;
          font-weight: normal;
          width: 100%;
        }

        .appointment {
          font-size: 14px;
          font-weight: normal;
        }

        .tile-icon {
          height: 40px;
          width: 40px;

          & > svg-icon svg {
            height: 40px;
            width: 40px;
          }
        }

        .state {
          & > svg-icon svg {
            height: 18px;
            width: 15px;
          }

          &__message {
            font-size: 13px;
          }
        }

        &.has-countdown {
          padding-bottom: 5px;
        }
      }

      &__countdown-content {
        border-bottom: 1px solid func.color(primary);
        border-right: 1px solid func.color(primary);
        border-left: 1px solid func.color(primary);
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
        background-color: func.color(primary, white);
      }
    }
  }
}
