@use 'functions/color' as func;

.material {
  &__input-helper {
    color: func.color(primary);
    display: block;
    font-size: 12px;
    line-height: 1.1;
    margin-bottom: 15px;
  }
}
