/* Modal */
// Styles in this sheet need to be reviewed as they might be old modal styles carried over from Faber v2

@media (width >= 769px) {
  .blur-wrapper {
    height: 100%;
  }
}

.blur {
  filter: blur(3px);
  overflow: hidden;
}

.no-overflow {
  overflow: hidden;
  min-height: 100vh;
  position: fixed;
}

.modal-fade {
  animation: fadein 300ms;
  background: rgb(0 0 0 / 40%);
  inset: 0;
  display: none;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 1;
}

.modal-fade.show-modal {
  display: block;
  animation: fadein 300ms;
}
