@use 'functions/color' as func;
@use 'settings/vars';
@use 'mixins/breakpoint';

/* stylelint-disable no-descending-specificity */
header {
  background: func.color(primary, base);
  height: 72px;
  position: fixed;
  left: 0;
  top: 0;
  transition:
    height 0.4s,
    left 0.7s ease;
  transform: translate3d(0, 0, 0);
  width: 100%;
  z-index: 5;

  .nav-open & {
    left: -256px;
  }

  .wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    margin: inherit;
    padding: 10px;
  }

  @include breakpoint.bp(min-width, vars.$large-min) {
    height: 120px;

    .wrapper {
      margin: 0 auto;
      padding: 20px 40px;
      width: 100%;
    }
  }

  .notification & {
    top: 195px;

    @include breakpoint.bp(min-width, vars.$mobile-min) {
      top: 170px;
    }
  }

  &.sticky {
    height: 72px;

    .icon-logo {
      &-tns,
      &-sng {
        height: 47px;
        width: 90px;

        svg {
          height: 47px;
          width: 90px;
        }
      }

      &-sng {
        width: 90px;

        svg {
          width: 90px;
        }
      }
    }
  }

  svg-icon {
    cursor: pointer;
    display: inline-block;
    outline: none;
  }

  .icon-logo {
    &-tns,
    &-sng {
      height: 47px;
      transition: all 0.4s;

      svg {
        bottom: -3px;
        height: 52px;
        transition: all 0.4s;
      }

      @include breakpoint.bp(min-width, vars.$large-min) {
        height: 90px;

        svg {
          height: 90px;
        }
      }
    }

    &-tns {
      width: 90px;

      svg {
        width: 90px;
      }

      @include breakpoint.bp(min-width, vars.$large-min) {
        width: 170px;

        svg {
          width: 170px;
        }
      }
    }

    &-sng {
      width: 90px;

      svg {
        width: 90px;
      }

      @include breakpoint.bp(min-width, vars.$large-min) {
        width: 144px;

        svg {
          width: 144px;
        }
      }
    }
  }

  .header-cta {
    display: none;

    .mat-small-button {
      min-width: 84px !important;
    }

    @include breakpoint.bp(min-width, vars.$large-min) {
      display: block;
      margin-left: auto;
      min-width: 188px;
    }
  }
}
