@use 'functions/color' as func;

/* stylelint-disable no-descending-specificity */
.date-container {
  .mat-datepicker-toggle {
    border-right: 1px solid func.color(greys, 100%);

    svg {
      top: -2px;
    }
  }

  &.disabled {
    input {
      color: func.color(greys, 40%);

      &:hover,
      &:focus {
        border-color: func.color(greys, 100%);
      }
    }
  }

  & mat-form-field .input-box {
    &.placeholder-text {
      &::after {
        color: func.color(greys, 40%);
      }
    }
  }

  & .mat-form-field-hide-placeholder .mat-input-element::placeholder,
  & .mat-input-element::placeholder {
    color: func.color(primary, grey);
    -webkit-text-fill-color: func.color(primary, grey);
  }
}
