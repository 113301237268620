@use 'settings/vars';
@use 'functions/color' as func;
@use 'mixins/breakpoint';

/* stylelint-disable no-descending-specificity */
.material {
  &.dynamic-control {
    &:has([dynamicValidationError]) {
      mat-form-field .mat-form-field-wrapper,
      mat-button-toggle-group,
      mat-checkbox {
        padding-bottom: 0 !important;
        margin: 0;
      }
    }

    width: 100%;

    label {
      margin-bottom: 0;
    }

    .invalid-state {
      color: func.color(primary, error);
      border-color: func.color(primary, error);
    }

    .mat-button-toggle-group {
      display: flex;

      mat-button-toggle {
        flex-grow: 1;

        &.mat-button-toggle-checked {
          background-color: func.color(primary, #ffc0cb);
          color: func.color(primary, white);
        }

        .mat-button-toggle-focus-overlay {
          opacity: 0 !important;
        }
      }
    }
  }
}

mat-checkbox.select-all {
  display: flex;
  padding-right: 0;

  label {
    flex-grow: 1;
    align-items: center;
    margin: 0;
    height: 100%;
    padding: 0;

    .mat-checkbox-label {
      width: 100%;
    }
  }
}

app-dynamic-form-component form.dynamic-form-container {
  .control-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    align-items: center;
    padding: 20px 0;

    @include breakpoint.bp(max-width, vars.$mobile-max) {
      grid-template-columns: 1fr;
    }

    .control {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &.divider {
      border-top: 1px solid func.color(greys, divider);
    }

    &:last-child {
      border-bottom: 1px solid func.color(greys, divider);
    }
  }

  h3 {
    font-size: 32px;
    font-weight: normal;
    margin: 24px 0;
  }

  .form-description {
    margin: 24px 0;
    padding: 16px 20px;
    border-radius: 6px;
    border: solid 1px rgb(0 54 83 / 25%);
    background-color: rgb(0 54 83 / 10%);
    font-size: 18px;
  }
}

app-dynamic-phone-number {
  mat-form-field.prefix {
    display: flex;

    &.mat-focused {
      left: 0;
      width: 100%;
    }

    .mat-form-field-wrapper {
      width: 100%;
    }

    .mat-form-field-infix {
      display: flex;
      flex-direction: row-reverse;

      .number {
        width: calc(100% - 75px);
        padding-left: 10px;

        .mat-input-element {
          bottom: -1px;
          position: relative;
        }
      }

      .country {
        width: 75px;
        border-right: 1px solid #c2c2c2;
      }

      .mat-form-field-label-wrapper {
        left: auto;
        right: 0;
        width: calc(100% - 85px);
      }
    }
  }
}
