@use 'mixins/breakpoint';
@use 'functions/color' as func;
@use 'settings/vars';

h1 {
  font-size: 26px;
  letter-spacing: 0.1px;
  line-height: 1;
  margin-bottom: 0.4em;

  @include breakpoint.bp(min-width, vars.$small-min) {
    font-size: 48px;
    line-height: 1.32;
    letter-spacing: -0.82px;
    margin-bottom: 0.2em;
  }
}

h2 {
  font-size: 32px;
  line-height: 37px;
  letter-spacing: 0.1px;
}

h3 {
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0.2px;
}

h4 {
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.2px;
}

h5 {
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.1px;
}

h6 {
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.2px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: func.color(primary, black);
  font-family: proxima-nova, Helvetica, Arial, sans-serif;

  &.bold {
    font-weight: vars.$font-weight-bold;
  }

  &.light {
    font-weight: vars.$font-weight-light;
  }

  &.blue {
    color: func.color(primary);
  }
}

.mat-form-field {
  font-family: proxima-nova, Helvetica, Arial, sans-serif;
}

p {
  color: func.color(primary, black);
  font-size: 16px;
  margin: 0 0 1.5em;
  line-height: 25px;

  &:last-child {
    margin: 0;
  }

  &.last {
    margin: 0;
  }

  &.blue {
    color: func.color(primary);
  }

  &.bold {
    font-weight: vars.$font-weight-semibold;
  }
}

a {
  font-size: 16px;
}
// text-editor tweak
.text-component p {
  font-family: proxima-nova, Helvetica, Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 25px;
}

.caption,
.view-only p {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  margin-bottom: 0;
}

.overline {
  font-family: proxima-nova, Helvetica, Arial, sans-serif;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  line-height: 14px;
}

.material {
  h1 {
    font-size: 64px;
    line-height: 1.32;
  }

  h2 {
    font-size: 48px;
    line-height: 37px;
  }

  h3 {
    font-size: 30px;
    line-height: 33px;
    letter-spacing: 0.2px;
  }
}

.asterisk {
  color: func.color(blues, dark);
  font-size: 14px;
}
