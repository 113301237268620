@use 'settings/vars';
@use 'functions/color' as func;

/* stylelint-disable no-duplicate-selectors */

/* stylelint-disable no-descending-specificity */

.material {
  display: block;
  position: relative;

  label {
    font-family: vars.$font-family-base;
  }

  mat-form-field {
    width: 100%;

    .mat-form-field-outline-start,
    .mat-form-field-outline-gap,
    .mat-form-field-outline-end {
      background-color: #fff;
    }

    &:not(.default) .mat-form-field-wrapper {
      margin: 0;
      padding-bottom: 30px;
    }

    &.last {
      .mat-form-field-wrapper {
        margin: 0;
        padding-bottom: 0;
      }
    }

    &.mat-focused {
      .mat-form-field-flex {
        color: func.color(primary, #000);

        .mat-form-field-outline {
          color: func.color(primary, #ffc0cb);
        }
      }

      .mat-form-field-label {
        color: func.color(primary, #ffc0cb);
      }
    }

    &.mat-form-field-appearance-outline {
      .mat-form-field-outline-thick {
        .mat-form-field-outline-end,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-start {
          border-width: 1px;
        }
      }
    }

    &.mat-form-field-invalid,
    &.ng-invalid.ng-dirty {
      &:not(.default) .mat-form-field-wrapper {
        padding-bottom: 10px;
      }

      .mat-form-field-label {
        color: func.color(primary, error);
      }

      .mat-form-field-flex {
        color: func.color(primary);

        .mat-form-field-outline {
          color: func.color(primary, error);
        }

        .mat-form-field-label-wrapper {
          color: func.color(primary, error);
        }
      }
    }

    &.mat-form-field-helper {
      .mat-form-field-wrapper {
        padding-bottom: 10px;
      }
    }

    &.mat-form-field-disabled {
      .mat-form-field-flex {
        color: func.color(greys, text);

        &:hover,
        &:focus,
        &:active {
          .mat-form-field-outline {
            color: func.color(borders, disabled);
          }
        }

        .mat-form-field-outline {
          color: func.color(borders, disabled);

          div {
            background: func.color(greys, light);
          }
        }
      }
    }

    .mat-form-field-flex {
      color: func.color(greys, text);
      font-size: 14px;
      height: 58px;

      &:hover,
      &:focus,
      &:active {
        .mat-form-field-outline {
          color: func.color(primary, #ffc0cb);
        }
      }

      .mat-form-field-outline {
        min-height: 54px;
      }

      .mat-form-field-outline {
        color: func.color(borders);
      }
    }

    .mat-form-field-prefix,
    .mat-form-field-suffix {
      top: 1px;
    }
  }
}
