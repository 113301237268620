@use 'settings/vars';
@use 'functions/color' as func;
@use 'sass:color';

.button-selection {
  position: relative;

  &__dropdown {
    background-color: func.color(primary, pink);
    border-radius: 0 0 5px 5px;
    margin-top: 1px;
    overflow: hidden;

    &.overlay {
      position: absolute;
      width: 100%;
      z-index: 20;
    }
  }

  &__choice {
    align-items: center;
    color: func.color(primary, white);
    display: flex;
    font-size: 16px;
    font-weight: vars.$font-weight-semibold;
    text-align: left;

    span {
      cursor: pointer;
      padding: 15px;
      width: 100%;
    }

    &:hover {
      background-color: color.adjust(
        func.color(primary, pink),
        $lightness: 20%
      );
    }
  }

  .mat-button-wrapper {
    align-items: center;
    display: flex;

    .mat-button-label {
      margin: 0 auto;
    }
  }

  .mat-button-dropdown {
    display: inline-block;
    border-left: 1px solid func.color(primary, white);
    padding: 0 4px 0 20px;

    .icon-triangle-down {
      svg {
        fill: #fff;
      }
    }
  }
}
