@use 'functions/color' as func;
@use 'settings/vars';
@use 'mixins/breakpoint';

/* Layout and Block Sizes (with 30px gutter) */

/* stylelint-disable no-descending-specificity */
main {
  padding-top: 72px;
  transition: all 0.2s linear 0s;
  height: 100%;
  min-height: 100vh;

  @include breakpoint.bp(min-width, vars.$large-min) {
    padding-top: 120px;
  }

  &.no-pad {
    padding: 0;
  }

  &.full-width {
    max-width: 100%;
    width: 100%;
  }

  .notification & {
    padding-top: 195px + 72px;

    @include breakpoint.bp(min-width, vars.$mobile-min) {
      padding-top: 170px + 72px;
    }

    @include breakpoint.bp(min-width, vars.$large-min) {
      padding-top: 170px + 120px;
    }
  }

  .authenticated & {
    @include breakpoint.bp(min-width, vars.$large-min) {
      display: flex;
    }

    > *:nth-child(2) {
      height: 100%;

      @include breakpoint.bp(min-width, vars.$large-min) {
        width: calc(100% - 256px);
      }
    }
  }

  .application & {
    display: flex;
    flex-direction: column-reverse;

    @include breakpoint.bp(min-width, vars.$large-min) {
      flex-direction: row;
    }

    > *:nth-child(2) {
      height: 100%;

      @include breakpoint.bp(min-width, vars.$large-min) {
        width: calc(100% - 256px);
      }
    }
  }

  > * {
    display: block;
  }
}

.wrapper {
  margin: 0 auto;
  max-width: 1200px;
}

body {
  #wrapper {
    min-height: 100vh;
    position: relative;
    left: 0;
    transition: 0.7s ease all;

    &.dash {
      background: func.color(greys, background);
    }
  }

  &.nav-open {
    position: fixed;

    #wrapper {
      left: -256px;
    }
  }

  &.modal-open {
    position: fixed;
    width: 100%;
  }
}
