// Icon size configuration
// -------------------------------------------------

/* stylelint-disable selector-class-pattern */

/* stylelint-disable no-descending-specificity */
.icon-arrow-right-circle {
  svg {
    height: 24px;
    width: 24px;
  }
}

.icon-arrow-head-left,
.icon-arrow-head-right {
  svg {
    height: 12px;
    width: 18px;
  }
}

.icon-calendar-blue,
.icon-calendar-grey {
  svg {
    height: 24px;
    width: 24px;
  }
}

.icon-complete-green {
  svg {
    height: 20px;
    width: 20px;
  }
}

.icon-eye-blue {
  svg {
    height: 16px;
    width: 16px;
  }
}

.icon-error-red {
  svg {
    height: 16px;
    width: 16px;
  }
}

.icon-file-active,
.icon-file-inactive {
  svg {
    height: 48px;
    width: 48px;
  }
}

.icon-information-grey,
.icon-information-white {
  svg {
    height: 16px;
    width: 16px;
  }
}

.icon-incomplete-grey {
  svg {
    height: 20px;
    width: 20px;
  }
}

.icon-issue-red,
.icon-issue-white {
  svg {
    height: 20px;
    width: 20px;
  }
}

.icon-magnifying-glass-grey {
  svg {
    height: 24px;
    width: 24px;
  }
}

.icon-pending-cyan {
  svg {
    height: 20px;
    width: 20px;
  }
}

.icon-radio-checked-active,
.icon-radio-unchecked-active,
.icon-radio-checked-inactive,
.icon-radio-unchecked-inactive {
  svg {
    height: 20px;
    width: 20px;
  }
}

.icon-arrow-prev-outline-inactive,
.icon-arrow-prev-outline-active,
.icon-arrow-next-outline-inactive,
.icon-arrow-next-outline-active,
.icon-arrow-next-solid-blue {
  svg {
    height: 32px;
    width: 32px;
  }
}

.icon-phone,
.icon-email {
  svg {
    height: 32px;
    width: 32px;
  }
}

.icon-celebrate {
  svg {
    height: 152px;
    width: 222px;
  }
}

.icon-qn-app {
  svg {
    height: 50px;
    width: 40px;
  }
}

.icon-qn-web {
  svg {
    height: 37px;
    width: 35px;
  }
}

.icon-first-shift {
  svg {
    height: 49px;
    width: 35px;
  }
}

.icon-phone-call {
  svg {
    height: 35px;
    width: 35px;
  }
}

.icon-clock {
  svg {
    width: 26px;
    height: 26px;
  }
}

.icon-smart-device {
  svg {
    width: 18px;
    height: 26px;
  }
}

.icon-rabbit {
  svg {
    width: 33px;
    height: 24px;
  }
}
