ul {
  &.list {
    margin: 20px 0;
    list-style: none;

    li + .align-top {
      align-items: flex-start;
    }

    li {
      align-items: center;
      display: flex;
      flex-flow: row wrap;
      margin: 0 0 10px;

      .list-inner {
        width: calc(100% - 33px);

        .address {
          white-space: pre-line;
        }
      }

      span,
      strong {
        margin: 0 5px 0 0;
      }

      &.edit {
        align-items: flex-start;

        &::before {
          bottom: -21px;
        }
      }

      &.confirmed {
        &::before {
          background-image: url('/assets/svg/tick-confirmation.svg');
          background-size: 21px 20px;
          bottom: 0;
          height: 20px;
          left: -3px;
          width: 21px;
        }
      }

      &::before {
        background-image: url('/assets/svg/arrow-head-right.svg');
        background-repeat: no-repeat;
        background-size: 12px 11px;
        bottom: -4px;
        content: '';
        display: inline-block;
        height: 20px;
        margin-right: 12px;
        position: relative;
        width: 21px;
      }
    }
  }

  &.no-top {
    margin: 0 0 20px;
  }

  &.no-center {
    li {
      align-items: flex-start;

      &::before {
        top: 7px;
      }
    }
  }

  &.app-declaration-list {
    font-size: 16px;

    li {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

ol {
  &.app-declaration-list {
    list-style: decimal;
  }
}
