@use 'settings/vars';
@use 'functions/color' as func;
@use 'mixins/breakpoint';

/* stylelint-disable selector-class-pattern */

/* stylelint-disable no-descending-specificity */
.profile-section__field-group {
  .field-group {
    &__container {
      &:first-child::before {
        display: none;
      }

      &.date-gap + .field-group__container {
        &::before {
          border: 1px dashed func.color(reds, error);
        }
      }
    }
  }
}

.field-group {
  &__row {
    @include breakpoint.bp(min-width, vars.$xsmall-min) {
      display: flex;
      flex-flow: row wrap;
    }

    .row {
      @include breakpoint.bp(min-width, vars.$xsmall-min) {
        margin: 0;
        width: 100%;
      }

      .col {
        @include breakpoint.bp(min-width, vars.$xsmall-min) {
          padding: 0;
        }
      }

      &__split {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px;

        + .row__split {
          .col {
            @include breakpoint.bp(min-width, vars.$xsmall-min) {
              padding-left: 10px;
              padding-right: 0;
            }
          }
        }

        @include breakpoint.bp(min-width, vars.$xsmall-min) {
          display: block;
          flex-wrap: nowrap;
          margin: 0;
          width: 50%;
        }

        .col {
          @include breakpoint.bp(min-width, vars.$xsmall-min) {
            padding-left: 0;
            padding-right: 10px;
          }
        }
      }
    }
  }

  &__container {
    background: func.color(primary, white);
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 7%);
    border-radius: 4px;
    margin-bottom: 30px;
    padding: 15px 20px;
    position: relative;

    &::before {
      border: 1px solid func.color(greys, border-dark);
      content: '';
      display: block;
      height: 30px;
      left: 50px;
      position: absolute;
      top: -30px;
      width: 0;
    }

    &.date-gap {
      border: 2px dashed func.color(reds, error);
      text-align: center;

      &::before {
        border: 1px dashed func.color(reds, error);
      }
    }

    &.rejected {
      border: 1px solid func.color(reds);
    }

    @include breakpoint.bp(min-width, vars.$medium-min) {
      padding: 30px 40px;
    }
  }

  &__inner {
    @include breakpoint.bp(min-width, vars.$medium-min) {
      display: flex;
      flex-flow: row wrap;
    }

    span {
      display: block;
      word-break: break-word;
    }
  }

  &__rejection {
    margin-top: 20px;
    padding: 0 20px;

    p {
      color: func.color(reds, error);
      font-weight: vars.$font-weight-semibold;
    }
  }

  &__name {
    .title {
      color: func.color(primary, black);
    }

    .subtitle {
      color: func.color(greys, text);
      font-size: 15px;

      @include breakpoint.bp(min-width, vars.$medium-min) {
        font-size: 15px;
      }
    }
  }

  &__name,
  &__type {
    display: flex;
    flex-flow: column wrap;
  }

  &__type,
  &__date {
    .title,
    .difference {
      color: func.color(greys, text);

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  &__date {
    display: flex;
    flex-direction: row;
  }

  &__actions {
    margin-left: auto;

    @include breakpoint.bp(max-width, vars.$medium-max) {
      position: static;
    }

    &-group-actions {
      display: none;
      flex-direction: row;
      justify-content: space-between;
      margin-left: -5px;
      margin-right: -5px;

      @include breakpoint.bp(min-width, vars.$medium-min) {
        display: flex;
      }
    }

    &-mobile-actions {
      position: absolute;
      right: 20px;
      top: 15px;

      .menuTrigger {
        cursor: pointer;
      }

      @include breakpoint.bp(min-width, vars.$medium-min) {
        display: none;
      }
    }
  }
}
