@use 'functions/color' as func;

.material {
  .error {
    color: func.color(primary, error);
    display: block;
    font-size: 12px;
    line-height: 1.1;
    margin-bottom: 10px;
    padding: 0 10px;

    * {
      color: func.color(primary, error);
      font-size: 12px;
      line-height: 1.1;
    }

    p {
      margin-bottom: 1em;
    }
  }
}
