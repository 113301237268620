@use 'settings/vars';
@use 'functions/color' as func;

/* stylelint-disable no-descending-specificity */
.material.mat-accordion {
  margin-bottom: 20px;

  &.lg {
    margin-bottom: 30px;
  }

  > .mat-expansion-panel {
    margin-bottom: 20px;

    &:last-child {
      margin: 0;
    }

    .mat-expansion-panel-header {
      font-family: vars.$font-family-base;
      padding: 0 15px;

      .mat-expansion-indicator {
        &::after {
          color: func.color(primary, #ffc0cb);
          padding: 5px;
          position: relative;
          top: -4px;
        }
      }
    }

    &.mat-expanded {
      .mat-expansion-panel-content {
        padding-bottom: 16px;
      }
    }

    .mat-expansion-panel-content {
      font-family: vars.$font-family-base;
      padding-left: 15px;
      padding-right: 15px;

      .mat-expansion-panel-body {
        border-top: 1px solid func.color(greys, module);
        padding: 16px 0 0;
      }
    }
  }

  .mat-expansion-panel-header-title {
    font-size: 18px;
    padding-bottom: 15px;
    padding-top: 15px;
  }
}
