@use 'settings/vars';
@use 'functions/color' as func;
@use 'sass:color';

/* stylelint-disable no-descending-specificity */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
main,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body,
html {
  min-height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  background: func.color(greys, background);
}

body {
  font-family: proxima-nova, Helvetica, Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 25px;
  overflow-x: hidden;
  text-rendering: optimizelegibility;
  text-size-adjust: 100%;
  color: func.color(primary, black);
}

* {
  box-sizing: border-box;
}

ol,
ul {
  list-style: none;
}

ul ul {
  margin: 5px 0 0 15px;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

th,
td {
  text-align: left;
  padding: 10px;
  font-size: 0.875em;
}

/* Links */

a,
span.link,
span.action {
  color: func.color(primary, link);
  cursor: pointer;
  margin: 0;
  text-decoration: none;
  transition: all 200ms ease;

  &.dark {
    color: func.color(primary, white);
  }

  &.disabled {
    cursor: default;
    color: func.color(greys, disabled);

    &:hover {
      color: func.color(greys, disabled);
    }
  }

  &:hover {
    color: color.adjust(func.color(primary, link), $lightness: -10%);
  }
}

.no-action {
  cursor: default !important;
}

a,
span {
  &.link {
    font-weight: vars.$font-weight-semibold;
  }
}

/* stylelint-disable scss/at-extend-no-missing-placeholder */
.md-action {
  @extend .action;

  text-decoration: underline !important;
}

/* stylelint-enable scss/at-extend-no-missing-placeholder */

a:hover {
  color: color.adjust(func.color(primary, link), $lightness: -10%);

  &.dark {
    color: color.adjust(func.color(primary, white), $lightness: -10%);
  }
}

a:focus {
  outline: thin dotted;
}

a:hover,
a:active {
  outline: 0;
}

.cursor-pointer {
  cursor: pointer;
}

/* Lists */

ul,
ol,
dl {
  margin: 0 0 1.5em 20px;
  padding: 0;
}

ul {
  list-style-type: disc;
}

li {
  margin: 0 0 8px;
  padding: 0;
}

label {
  font-weight: normal;
  display: inline-block;
  margin-bottom: 16px;
  padding-right: 25px;
}

input {
  font-size: 18px;
}

textarea {
  font-family: 'Proxima Nova Regular', Helvetica, Arial, sans-serif;
}

/* Transitions */
.transition {
  transition: 200ms all ease;
}

@keyframes fadein {
  0% {
    display: block;
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
