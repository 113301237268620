@use 'settings/vars';
@use 'functions/color' as func;

.material.textarea {
  mat-form-field {
    .mat-form-field-flex {
      height: auto;

      .mat-form-field-outline {
        height: auto;
      }
    }
  }
}

.material {
  .counter {
    margin-bottom: 10px;
  }

  &__character-counter.textarea {
    color: func.color(blues, light);
    font-size: 14px;
    font-weight: vars.$font-weight-normal;
    line-height: 12px;
    opacity: 0.4;
    position: absolute;
    right: 0;
    text-align: right;
    bottom: 7px;
    top: unset;
    transform: none;
    width: 150px;
  }
}
