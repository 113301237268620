@use './colours' as colours;
@use '../functions/ranges';
// Media query ranges
// -------------------------------------------------
$mobile-breakpoint: 480px;
$xsmall-breakpoint: 601px;
$small-breakpoint: 767px;
$medium-breakpoint: 969px;
$large-breakpoint: 1200px;
$mobile-range: (370px, $mobile-breakpoint);
$xsmall-range: ($mobile-breakpoint, $xsmall-breakpoint);
$small-range: ($xsmall-breakpoint, $small-breakpoint);
$medium-range: ($small-breakpoint + 1px, $medium-breakpoint);
$large-range: ($medium-breakpoint + 1px, $large-breakpoint);
$screen: 'only screen';
$landscape: '#{$screen} and (orientation: landscape)';
$portrait: '#{$screen} and (orientation: portrait)';
$mobile-min: ranges.lower-bound($mobile-range);
$mobile-max: ranges.upper-bound($mobile-range);
$xsmall-min: ranges.lower-bound($xsmall-range);
$xsmall-max: ranges.upper-bound($xsmall-range);
$small-min: ranges.lower-bound($small-range);
$small-max: ranges.upper-bound($small-range);
$medium-min: ranges.lower-bound($medium-range);
$medium-max: ranges.upper-bound($medium-range);
$large-min: ranges.lower-bound($large-range);
$large-max: ranges.upper-bound($large-range);
// Fonts Styles
$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-ultra: 900;
$font-family-base: proxima-nova, helvetica, arial, sans-serif;
