@use 'functions/color' as func;

/* stylelint-disable no-descending-specificity */
.uploader-wrapper {
  .input-box {
    .caption {
      &.default {
        color: func.color(primary, black);
      }

      &.error {
        color: func.color(status, error);
      }

      &.complete {
        color: func.color(status, complete);
      }
    }
  }

  .files {
    &-item {
      mat-icon {
        color: func.color(primary, base);
      }

      &:not(.complete),
      &.complete:hover {
        mat-icon {
          color: func.color(blues, 80%);
        }
      }

      &:not(.complete) {
        .files-data {
          .text-container {
            color: func.color(greys, 100%);
          }
        }
      }

      .files-data {
        .text-container {
          color: func.color(primary, black);
        }

        .progress-bar {
          background-color: func.color(greys, 20%);

          .bar {
            background-color: func.color(primary, base);
          }
        }
      }
    }
  }
}
