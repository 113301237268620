.material.date {
  .datepicker-toggle {
    position: absolute;
    right: -5px;
    top: 0;

    .icon-calendar,
    .icon-calendar-disabled {
      height: 19px;
      position: relative;
      vertical-align: text-top;
      width: 18px;
    }
  }
}

.mat-datepicker-popup {
  .mat-calendar-controls {
    align-items: center;
    margin: 0;
  }
}
