@use 'functions/color' as func;
// Icon size configuration
// -------------------------------------------------

/* stylelint-disable selector-class-pattern */

/* stylelint-disable no-descending-specificity */
svg-icon {
  display: inline-flex;
}

div[class*='icon-'] {
  display: inherit;
  position: relative;

  svg {
    display: inline-block;
    position: relative;
  }
}

.icon-add-to-calendar {
  svg {
    height: 18px;
    width: 18px;
  }
}

.icon-avatar1,
.icon-avatar2 {
  svg {
    height: 43px;
    width: 43px;
  }
}

.icon-arrow-left,
.icon-arrow-right {
  svg {
    height: 20px;
    width: 12px;
  }
}

.icon-calendar {
  svg {
    height: 19px;
    width: 18px;
  }
}

.icon-circle-outline {
  svg {
    height: 20px;
    width: 21px;
  }
}

.icon-close {
  svg {
    height: 24px;
    width: 24px;
  }
}

.icon-contact {
  svg {
    height: 24px;
    width: 24px;
  }
}

.icon-blog {
  svg {
    height: 24px;
    width: 20px;
  }
}

.icon-cross-circle {
  svg {
    height: 24px;
    width: 24px;
  }
}

.icon-error {
  svg {
    height: 20px;
    width: 20px;
  }
}

.icon-eye {
  svg {
    height: 13px;
    width: 18px;
  }
}

.icon-eye-open {
  svg {
    height: 18px;
    width: 30px;
  }
}

.icon-eye-slash {
  svg {
    height: 24px;
    width: 30px;
  }
}

.icon-hamburger-menu {
  svg {
    height: 25px;
    width: 25px;
  }
}

.icon-higher_education {
  svg {
    height: 16px;
    width: 27px;
  }
}

.icon-home {
  svg {
    height: 17px;
    width: 21px;
  }
}

.icon-information {
  color: func.color(blues, light);

  svg {
    height: 28px;
    width: 28px;
  }
}

.icon-kebab-menu {
  svg {
    height: 16px;
    width: 4px;
  }
}

.icon-leave_of_absence {
  svg {
    height: 22px;
    width: 22px;
  }
}

.icon-logOut {
  svg {
    height: 17px;
    width: 21px;
  }
}

.icon-logo-apple {
  svg {
    height: 21px;
    width: 17px;
  }
}

.icon-logo-google {
  svg {
    height: 19px;
    width: 19px;
  }
}

.icon-logo-nursing {
  height: 36px;
  width: 31px;

  svg {
    height: 36px;
    width: 31px;
  }
}

.icon-logo-office-365 {
  svg {
    height: 18px;
    width: 16px;
  }
}

.icon-logo-outlook {
  svg {
    height: 19px;
    width: 19px;
  }
}

.icon-logo-outlook-com {
  svg {
    height: 19px;
    width: 19px;
  }
}

.icon-logo-yahoo {
  svg {
    height: 17px;
    width: 21px;
  }
}

.icon-livechat {
  height: 28px;
  width: 28px;

  svg {
    height: 28px;
    width: 28px;
  }
}

.icon-map-pin {
  svg {
    height: 25px;
    width: 18px;
  }
}

.icon-menu-help {
  svg {
    height: 23px;
    width: 23px;
  }
}

.icon-menu-home {
  svg {
    height: 23px;
    width: 23px;
  }
}

.icon-padlock {
  svg {
    height: 19px;
    width: 15px;
  }
}

.icon-plus {
  svg {
    height: 14px;
    stroke-width: 2;
    width: 14px;
  }
}

.icon-question-mark {
  svg {
    height: 17px;
    width: 11px;
  }
}

.icon-search {
  svg {
    height: 27px;
    width: 25px;
  }
}

.icon-triangle-down {
  svg {
    height: 9px;
    width: 9px;
  }
}

.icon-tick {
  svg {
    height: 24px;
    width: 24px;
  }
}

.icon-tick-confirmation {
  svg {
    height: 20px;
    width: 21px;
  }
}

.icon-warning {
  svg {
    height: 19px;
    width: 22px;
  }
}

.icon-work_history {
  svg {
    height: 18px;
    width: 22px;
  }
}

.icon-livechat-bg {
  background-color: func.color(pinks, 80%);
  border-radius: 50%;
  height: 28px;
  position: relative;
  top: -2px;
  width: 28px;

  div.icon-livechat {
    height: 17px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 17px;

    svg {
      height: 17px;
      width: 17px;
    }
  }
}

.icon-calendar-grid {
  width: 27px;
  height: 22px;
  overflow: hidden;
  margin-left: 10px;
  vertical-align: middle;
}

.icon-checked-calendar {
  width: 23px;
  height: 27px;
  color: func.color(pinks, 80%);
  overflow: hidden;
}

.icon-person:not(svg-icon) {
  width: 16px;
  height: 16px;
  color: func.color(pinks, 80%);
  overflow: hidden;
  margin-right: 10px;
}

.icon-phone2:not(svg-icon) {
  width: 16px;
  height: 16px;
  color: func.color(pinks, 80%);
  overflow: hidden;
  margin-right: 10px;
}

.icon-circle-check:not(svg-icon) {
  width: 16px;
  height: 16px;
  overflow: hidden;
  margin-right: 10px;
}
