@use 'settings/vars';
@use 'functions/color' as func;
@use 'mixins/breakpoint';

/* stylelint-disable selector-class-pattern */

/* stylelint-disable no-descending-specificity */
.material {
  &.counter {
    mat-form-field {
      .mat-form-field-flex {
        padding: 0 78px 0 10px;
      }

      .mat-form-field-infix {
        .mat-input-element {
          width: calc(100% - 60px);
        }
      }
    }
  }

  mat-form-field {
    &.outputIcon {
      @include breakpoint.bp(max-width, vars.$medium-max) {
        width: calc(100% - 40px);
      }
    }
  }

  &__character-counter {
    color: func.color(blues, light);
    font-size: 14px;
    font-weight: vars.$font-weight-normal;
    line-height: 12px;
    opacity: 0.4;
    position: absolute;
    right: -65px;
    text-align: right;
    top: 50%;
    transform: translateY(-55%);
    width: 125px;
  }

  div[class*='inputIcon'] {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  div[class*='outputIcon'] {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 10px;

    @include breakpoint.bp(min-width, vars.$large-min) {
      right: -40px;
    }
  }
}

.password-validity {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 11%);
  padding: 15px 20px;
  position: absolute;
  max-width: 316px;
  right: -15px;
  top: -250px;
  z-index: 1;

  @include breakpoint.bp(min-width, vars.$large-min) {
    right: -355px;
    top: 0;
  }

  .title {
    margin-bottom: 7px;
  }

  .icon-circle-outline,
  .icon-tick {
    svg {
      bottom: -3px;
      height: 16px;
      right: -2px;
      width: 16px;
    }
  }

  .icon-tick {
    svg {
      fill: func.color(greens, success);
    }
  }

  &::before,
  &::after {
    content: '';
    filter: drop-shadow(0 2px 3px rgb(0 0 0 / 11%));
    height: 0;
    position: absolute;
    width: 0;

    @include breakpoint.bp(max-width, vars.$medium-max) {
      bottom: -15px;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid transparent;
      border-top-color: white;
      right: 15px;
    }

    @include breakpoint.bp(min-width, vars.$large-min) {
      border-right: 15px solid transparent;
      border-right-color: white;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      filter: drop-shadow(-2px 0 3px rgb(0 0 0 / 11%));
      left: -15px;
      top: 10px;
    }
  }

  &::before {
    z-index: 1;
  }

  &::after {
    filter: none;
    z-index: 2;

    @include breakpoint.bp(max-width, vars.$medium-max) {
      bottom: -12px;
    }

    @include breakpoint.bp(min-width, vars.$large-min) {
      left: -12px;
    }
  }

  ul {
    color: func.color(primary, black);
    margin: 0;

    li {
      align-items: flex-start;
      display: flex;
      list-style: none;
      margin-bottom: 2px;

      span {
        padding-left: 10px;
      }
    }
  }

  .green {
    color: green;
  }
}
