@use 'functions/color' as func;
@use 'settings/vars';
@use 'mixins/breakpoint';

footer {
  background: func.color(primary, base);
  bottom: 0;
  position: fixed;
  left: 0;
  transform: translate3d(0, 0, 0);
  width: 100%;
  z-index: 5;

  @include breakpoint.bp(min-width, vars.$large-min) {
    display: none;
  }

  .mat-small-button {
    min-width: 84px !important;
  }

  .wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    margin: inherit;
    padding: 10px;
  }

  .footer-cta {
    display: block;
    min-width: 188px;
  }
}
