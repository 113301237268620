@use 'functions/color' as func;

/* stylelint-disable selector-class-pattern */
.fileInput {
  border: none;
  height: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  visibility: hidden;
  width: 0;
}

.material.file {
  mat-form-field {
    .mat-input-element {
      cursor: default;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }

  .action {
    color: func.color(primary, link);
    text-decoration: underline;

    &.disabled {
      pointer-events: none;
      color: func.color(greys, 80%);
      text-decoration: none;
    }

    &.view {
      margin-right: 15px;
    }
  }
}
