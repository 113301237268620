@use 'mixins/alert';
@use 'settings/vars';
@use 'functions/color' as func;
@use 'sass:color';
// Alerts
// --------------------------------------------------
// Base styles
// -------------------------

/* stylelint-disable no-descending-specificity */
.alert {
  border: 1px solid transparent;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  padding: 0.75rem 1.25rem;
  text-align: center;
  // Headings for larger alerts

  h4 {
    color: inherit; // Specified for the h4 to prevent conflicts of changing $headings-color
    margin-top: 0;
  }
  // Provide class for links that match alerts
  // Improve alignment and spacing of inner content

  > p,
  > ul {
    margin-bottom: 0;
  }

  > p + p {
    margin-top: 5px;
  }
}
// Dismissible alerts
// Expand the right padding and account for the close button's positioning.
// The misspelled .alert-dismissable was deprecated in 3.2.0.

.alert-dismissable,
.alert-dismissible {
  // Adjust close link position

  .close {
    color: inherit;
    position: relative;
    right: -21px;
    top: -2px;
  }
}
// Alternate styles
// Generate contextual modifier classes for colorizing the alert.

.alert-success {
  @include alert.alert-variant(
    #dbf9e9,
    color.adjust(#c4e9d5, $hue: -10, $lightness: -5%),
    2px,
    #148045
  );

  padding: 20px 27px;

  a,
  p,
  span {
    color: #148045;
  }

  a {
    font-weight: vars.$font-weight-bold;
  }
}

.alert-info {
  @include alert.alert-variant(
    #d9edf7,
    color.adjust(#d9edf7, $hue: -10, $lightness: -7%),
    1px,
    #31708f
  );

  a,
  p,
  span {
    color: #31708f;
  }

  a {
    font-weight: vars.$font-weight-bold;
  }
}

.alert-warning {
  @include alert.alert-variant(
    #fcf8e3,
    color.adjust(#fcf8e3, $hue: -10, $lightness: -5%),
    2px,
    #8a6d3b
  );

  padding: 20px 27px;

  a,
  p,
  span {
    color: #8a6d3b;
  }

  a {
    font-weight: vars.$font-weight-bold;
  }
}

.alert-danger {
  @include alert.alert-variant(
    #f3d8da,
    color.adjust(#efcbce, $hue: -10, $lightness: -5%),
    2px,
    #7f3e41
  );

  padding: 20px 27px;

  a,
  p,
  span {
    color: #7f3e41;
  }

  a {
    font-weight: vars.$font-weight-bold;
  }
}

.alert-information {
  @include alert.alert-variant(
    #dde2e6,
    color.adjust(rgba(0, 54, 83, 0.25), $hue: -10, $lightness: -5%),
    1px,
    #0f2c3c
  );

  padding: 20px 27px;

  a,
  p,
  span {
    color: #0f2c3c;
  }
}

.rejection-status {
  margin: 30px 0 0;
}

.status {
  color: func.color(primary, grey);

  svg {
    bottom: -1px;
    height: 16px;
    width: 16px;
  }

  &-content {
    color: func.color(primary, black);
  }

  &.complete {
    color: func.color(status, complete);
  }

  &.issue,
  &.pending {
    color: func.color(primary, white);
  }

  &.issue {
    border-color: func.color(status, error);

    .status-header {
      background-color: func.color(status, error);
    }
  }

  &.pending {
    border-color: func.color(status, pending);

    .status-header {
      background-color: func.color(status, pending);
    }
  }
}

.state-detection {
  &.complete {
    color: func.color(status, complete);
  }

  &.issue {
    color: func.color(status, error);
  }
}
