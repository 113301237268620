@use 'functions/color' as func;

.tooltip-container {
  .tooltip-close {
    color: func.color(primary, base);
  }

  .tooltip-message {
    background-color: func.color(greys, 40%);
    color: func.color(primary, base);
  }

  .triangle {
    &::after {
      background-color: func.color(greys, 40%);
    }
  }
}

.tooltip-icon {
  span {
    border: 1px solid func.color(greys, 100%);
    color: func.color(greys, 100%);
  }

  &:hover {
    & span {
      color: func.color(primary, black);
      border-color: func.color(primary, black);
    }
  }
}

.group {
  .tooltip-icon {
    span {
      border-color: func.color(primary, white);
      color: func.color(primary, white);
    }
  }
}
