@use 'settings/vars';
@use 'mixins/breakpoint';

/* stylelint-disable no-descending-specificity */
.inline-edit {
  display: inline-block;

  &.telephone-number {
    .mat-form-field-wrapper {
      padding-bottom: 0 !important;

      .mat-form-field-infix {
        width: 200px;
      }
    }
  }

  [hidden] {
    display: none;
  }

  &__label {
    display: block;

    @include breakpoint.bp(min-width, vars.$small-min) {
      display: inline;
    }
  }

  .text {
    font-weight: vars.$font-weight-bold;
  }

  &__inner {
    align-items: center;
    display: flex;
    flex-flow: row wrap;

    @include breakpoint.bp(min-width, 383px) {
      flex-wrap: nowrap;
    }
  }

  &__actions {
    margin-top: 10px;
    margin-left: -20px;
    margin-right: -20px;
    width: 100%;

    @include breakpoint.bp(min-width, 383px) {
      margin: 0;
      width: auto;
    }
  }

  .mat-form-field-wrapper {
    padding-bottom: 0 !important;

    .mat-form-field-infix {
      width: 110px;
    }
  }

  .mat-input-element {
    width: auto;

    @include breakpoint.bp(min-width, 383px) {
      margin-bottom: 0;
    }
  }

  .action {
    margin: 0;
    padding: 0 20px;

    &:last-child {
      padding-left: 0;
    }
  }

  .material-error {
    margin-top: 10px;
  }
}
