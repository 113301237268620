@use 'functions/color' as func;

.progressbar {
  & li {
    background-color: func.color(greys, 80%);
    color: func.color(primary, white);

    &::after {
      background-color: func.color(greys, 80%);
    }

    &.completed {
      background-color: func.color(primary, base);
    }

    &.issue {
      background-color: func.color(status, error);
    }

    &.active {
      background-color: func.color(status, complete);
    }
  }
}

.feedbackbar {
  background-color: func.color(primary, white);

  &__progressComplete {
    background-color: func.color(greys, 40%);

    & .progress {
      background-color: func.color(status, complete);
    }
  }

  &__percentageComplete {
    span {
      color: func.color(status, complete);
    }
  }
}

.timeline-progress {
  background-color: func.color(primary, white);
  border: 1px solid func.color(borders);
  border-radius: 4px;
  margin-bottom: 30px;
  padding: 20px;

  &__info {
    p {
      font-size: 14px;
      margin-bottom: 10px;
      color: #898989 !important;

      &.helper-text {
        color: #003653 !important;
        font-weight: 600;
      }
    }
  }

  &__status {
    color: func.color(primary);
    font-size: 14px;
    margin-bottom: 10px;
  }

  &__message {
    color: func.color(greys, text);
    font-size: 14px;
    margin-bottom: 10px;

    &:last-child {
      margin: 0;
    }

    .icon-tick-confirmation svg {
      bottom: -5px;
      margin-right: 5px;
    }

    .icon-warning svg {
      bottom: -3px;
      margin-right: 5px;
    }

    &.error {
      color: func.color(reds, error) !important;
    }
  }

  &__bar {
    background-color: #ebeff2;
    border-radius: 4px;
    height: 4px;
    margin-bottom: 15px;
    position: relative;
    width: 100%;

    .progress {
      border-radius: 4px;
      height: 4px;

      &.incomplete {
        background: func.color(status, error);
      }

      &.pending {
        background-color: func.color(status, pending);
      }

      &.in-progress {
        background-color: func.color(status, progress);
      }

      &.complete {
        background-color: func.color(status, complete);
      }
    }
  }
}
