@use 'settings/vars';
@use 'functions/color' as func;
@use 'mixins/breakpoint';
@use 'sass:math';

/* stylelint-disable no-descending-specificity */
.material {
  &.large {
    .material {
      &__checkbox-group {
        // workaround for https://github.com/angular/components/issues/25153
        .mat-checkbox {
          transform: translateZ(0);
        }

        mat-checkbox {
          flex-basis: auto;
          flex-shrink: 1;
          width: auto;
        }

        &--list mat-checkbox {
          flex: 0 1 auto;
          width: 100%;
        }
      }
    }
  }

  &__checkbox-group {
    border: 1px solid func.color(greys, material);
    border-radius: 5px;
    margin-bottom: 30px;
    padding: 18px;
    // workaround for https://github.com/angular/components/issues/25153
    .mat-checkbox {
      transform: translateZ(0);
    }

    mat-label {
      color: func.color(primary);
      display: block;
      font-size: 14px;
      margin-bottom: 5px;
    }

    mat-checkbox {
      color: func.color(primary);
      flex-basis: auto;
      flex-shrink: 1;
      font-family: vars.$font-family-base;
      padding-bottom: 10px;
      padding-left: 8px;
      padding-right: 8px;
      width: 100%;

      .mat-checkbox-layout {
        font-size: 14px;
        margin: 0;
        padding: 0;
        white-space: pre-line;
      }

      @include breakpoint.bp(min-width, vars.$xsmall-min) {
        width: math.div(100%, 3);
      }

      @include breakpoint.bp(min-width, vars.$medium-min) {
        width: math.div(100%, 4);
      }

      @include breakpoint.bp(min-width, vars.$large-min) {
        width: math.div(100%, 5);
      }
    }

    .mat-checkbox-group {
      display: flex;
      flex-flow: row wrap;
      margin-left: -8px;
      margin-right: -8px;

      & .disabled-checkbox {
        mat-label,
        .mat-checkbox-label {
          color: func.color(greys, 40%);
        }
      }
    }

    &.invalid {
      border: 1px solid func.color(primary, error);

      mat-label,
      .mat-checkbox-label {
        color: func.color(primary, error);
      }

      .mat-checkbox-group {
        padding-bottom: 10px;
      }
    }

    &-helper {
      margin-bottom: 15px;
    }

    &--list {
      border: 0 !important;
      padding: 0;

      &.column-list {
        .mat-checkbox-group {
          @include breakpoint.bp(min-width, vars.$small-min) {
            column-count: 2;
          }

          @include breakpoint.bp(min-width, vars.$medium-min) {
            column-count: 3;
          }
        }
      }

      mat-label,
      .mat-checkbox-label {
        color: func.color(primary, black);
      }

      .mat-checkbox-group {
        display: block;
        column-count: 1;
        margin: 0;
      }

      mat-checkbox {
        display: inline-flex;
        flex: 0 1 auto;
        padding: 4px 8px 4px 0;
        width: 100%;

        .mat-checkbox-layout {
          display: flex;
          font-size: 14px;
          margin: 0;
          padding: 0;
          white-space: pre-line;
        }
      }
    }
  }
}
