@use 'settings/vars';
@use 'functions/color' as func;

/* stylelint-disable selector-class-pattern */

/* stylelint-disable no-descending-specificity */
.material {
  &.single {
    .material__checkbox {
      label {
        color: func.color(primary, black);
        margin-bottom: 0;

        .mat-checkbox-label {
          line-height: 22px;
          white-space: pre-wrap;
        }

        .mat-checkbox-inner-container {
          height: 22px;
          margin-right: 14px;
          width: 22px;
        }
      }
    }
  }

  .material__checkbox {
    color: func.color(greys, text);

    label {
      font-family: vars.$font-family-base;
      font-weight: 300;
      margin-bottom: 30px;
      white-space: normal;
    }
  }
}
