@use 'settings/vars';
@use 'functions/color' as func;
@use 'mixins/breakpoint';

/* stylelint-disable no-descending-specificity */
.material {
  .mat-select-value {
    color: func.color(greys, text);
  }

  .mat-select-arrow-wrapper {
    vertical-align: bottom;
  }

  &__radio-group {
    border: 1px solid func.color(greys, material);
    border-radius: 5px;
    margin-bottom: 30px;
    padding: 18px;

    mat-label {
      color: func.color(primary);
      display: block;
      font-size: 14px;
      margin-bottom: 5px;
    }

    mat-radio-group {
      color: func.color(primary);
      display: flex;
      flex-flow: row wrap;
      margin-left: -8px;
      margin-right: -8px;
    }

    mat-radio-button {
      flex-basis: auto;
      flex-shrink: 1;
      font-family: vars.$font-family-base;
      padding-bottom: 10px;
      padding-left: 8px;
      padding-right: 8px;
      width: 100%;

      @include breakpoint.bp(min-width, vars.$xsmall-min) {
        width: auto;
      }
    }

    .mat-radio-label {
      font-size: 14px;
      margin: 0;
      padding: 0;
    }

    &-helper {
      margin-bottom: 15px;
    }

    &--list {
      border: 0 !important;
      padding: 0;
      // workaround for https://github.com/angular/components/issues/25153
      .mat-radio-button {
        transform: translateZ(0);
      }

      &.column-list {
        mat-radio-group {
          @include breakpoint.bp(min-width, vars.$small-min) {
            column-count: 2;
          }
        }
      }

      mat-label,
      .mat-radio-label {
        color: func.color(primary, black);
      }

      mat-radio-group {
        display: block;
        column-count: 1;
        margin: 0;
      }

      mat-radio-button {
        -webkit-margin-before: -4px;
        -webkit-margin-after: 4px;
        display: inline-flex;
        flex: 0 1 auto;
        padding: 4px 8px 4px 0;
        width: 100%;
      }
    }

    &.invalid {
      border-color: func.color(primary, error);

      mat-radio-group {
        padding-bottom: 10px;
      }

      mat-label,
      .mat-radio-label {
        color: func.color(primary, error);
      }
    }
  }

  .mat-radio-container,
  .mat-radio-outer-circle,
  .mat-radio-inner-circle {
    height: 18px;
    width: 18px;
  }
}

.mat-select-panel {
  .mat-option {
    color: #808080 !important;

    &:hover {
      background: rgb(0 47 73 / 10%) !important;
    }

    &.mat-selected {
      background: rgb(0 47 73 / 20%) !important;
      color: #808080 !important;
    }
  }
}

.block-radio-group {
  display: block;

  &.list {
    display: flex;
    flex-flow: column wrap;
  }
}

.block-label-title {
  display: block;
  font-size: 26px;
  margin-bottom: 20px;
}

.mat-radio-group {
  .mat-radio-label {
    display: flex;
    white-space: normal;
  }
}
