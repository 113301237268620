@use 'settings/vars';
@use 'functions/color' as func;
@use 'mixins/breakpoint';
@use 'sass:color';

/* stylelint-disable selector-class-pattern */

/* stylelint-disable no-descending-specificity */
button.mat-button {
  border-radius: 5px;
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  font-weight: bold;
  height: 54px;
  line-height: 2.22;
  min-width: 160px;
  width: 100%;

  @include breakpoint.bp(min-width, vars.$large-min) {
    width: auto;
  }

  &.mat-add-calendar {
    border: 1px solid #ededed;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 8%);

    .mat-add-calendar__icon {
      line-height: 1;
    }

    .icon-add-to-calendar {
      bottom: -2px;
    }

    .mat-button-label {
      padding: 0 10px;
    }

    .mat-center-icon {
      margin-left: auto;
    }
  }

  .mat-icon {
    margin-top: 0 !important;
  }

  .icon-link {
    width: 38px;
    height: 37px;
  }

  &.mat-no-margin {
    margin: 0 !important;
  }

  &.mat-auto-margin {
    margin: 0 auto !important;
  }

  &.mat-md-button {
    @include breakpoint.bp(min-width, vars.$medium-min) {
      min-width: 230px;
    }
  }

  &.mat-wide-button {
    @include breakpoint.bp(min-width, vars.$small-min) {
      min-width: 272px;
    }
  }

  &.mat-full-width {
    width: 100%;
  }

  &.mat-cta-icon,
  &.mat-add-calendar {
    .mat-button-wrapper {
      align-items: center;
      display: flex;
      justify-content: center;
      gap: 20px;
    }
  }

  &.mat-cta-icon {
    .mat-button-label {
      padding: 0 10px;
    }

    .mat-center-icon {
      margin-right: auto;
    }
  }

  .icon-arrow-right-circle {
    height: 24px;
    margin-left: auto;
    width: 24px;
  }

  &.mat-flat-button,
  &.mat-raised-button {
    color: func.color(primary, white);
    background-color: func.color(primary, pink);

    &.inverted {
      color: func.color(primary, pink);
      background-color: func.color(primary, white);

      &:hover {
        background-color: func.color(primary, white);
      }

      &:active:hover {
        background-color: func.color(secondary, blue);
      }

      &[disabled],
      &[disabled]:hover {
        color: white;
        background-color: func.color(pinks, 10%);
      }
    }

    &.light {
      &:hover {
        background-color: color.adjust(
          func.color(primary, pink),
          $lightness: 10%
        );
      }
    }

    .icon-arrow-right-circle {
      fill: white;
    }

    &.mat-small-button {
      font-size: 14px;
      height: 32px;
      min-width: 0;

      .icon-arrow-right-circle {
        width: 13px;
        height: 13px;
      }

      .mat-icon {
        margin-top: -2px;
      }
    }

    &.mat-medium-button {
      font-size: 16px;
      height: 40px;

      .icon-arrow-right-circle {
        width: 15px;
        height: 15px;
      }

      .mat-icon {
        margin-top: -2px;
      }
    }

    &[disabled],
    &[disabled]:hover {
      background-color: func.color(pinks, 10%);
      color: func.color(primary, white);
    }

    &:hover {
      background-color: func.color(pinks, 40%);
    }

    &:active {
      background-color: func.color(pinks, 30%);
      color: func.color(primary, white);
    }

    &.cdk-focused {
      background-color: func.color(pinks, 30%);
    }
  }

  &.mat-stroked-button {
    color: func.color(primary, pink);
    border: 1.5px solid func.color(primary, pink);

    &:active {
      color: func.color(secondary, blue);
      border-color: func.color(secondary, blue);
    }

    &:hover {
      background-color: func.color(primary, pink);
      color: white;
    }

    .icon-arrow-right-circle {
      fill: func.color(primary, pink);
    }

    &.mat-small-button {
      font-size: 14px;
      height: 32px;

      .icon-arrow-right-circle {
        width: 13px;
        height: 13px;
      }

      .mat-icon {
        margin-top: -2px;
      }
    }

    &.cta-icon {
      color: func.color(primary);
      border: 1.5px solid func.color(primary);

      svg {
        fill: func.color(primary);
        float: right;
        height: 20px;
        width: 20px;
      }
    }

    &[disabled],
    &[disabled]:hover {
      color: func.color(pinks, 10%);
      border-color: func.color(pinks, 20%);
      background-color: func.color(primary, white);
    }

    &.inverted {
      border-color: func.color(primary, white);
      color: func.color(primary, white);

      &:active {
        color: func.color(secondary, blue);
        border-color: func.color(secondary, blue);

        &:hover {
          background-color: transparent;
        }
      }

      &[disabled],
      &[disabled]:hover {
        border-color: rgb(255 255 255 / 20%);
        background-color: transparent;
        color: rgb(255 255 255 / 60%);
      }

      &:hover {
        background-color: rgb(255 255 255 / 40%);
      }
    }
  }

  &.mat-flat-button {
    &.inverted {
      &:hover {
        background-color: rgb(255 255 255 / 80%);
      }

      &[disabled],
      &[disabled]:hover {
        color: rgb(255 255 255 / 60%);
        background-color: rgb(255 255 255 / 20%);
      }
    }
  }

  &.mat-white-button {
    border-color: func.color(primary, white);
    background-color: func.color(primary, white);
    color: func.color(primary, black);

    &:hover {
      background-color: color.adjust(
        func.color(primary, white),
        $lightness: -2%
      );
      color: func.color(primary, black);
    }
  }

  &.success {
    border-color: func.color(greens, base);
    color: func.color(greens, base);

    &:hover {
      border-color: func.color(greens, success);
      color: func.color(greens, success);
      background-color: color.adjust(
        func.color(primary, white),
        $lightness: -1%
      );
    }
  }
}
