/* stylelint-disable no-descending-specificity */
.garda-table,
.terms-table {
  th,
  td {
    font-size: 18px;
    padding: 3px 10px;
  }

  ul {
    margin: 0 0 0 30px;
  }

  &-wrapper {
    padding: 0 10px;
  }
}

.terms-table {
  th,
  td {
    font-size: 16px;
    padding: 0 10px;
  }

  td {
    &:first-child {
      width: auto;
    }

    width: 100%;
  }
}

.app-induction {
  th,
  td {
    font-size: 16px;
    padding: 0 10px;
  }

  h3 {
    margin-bottom: 0.9em;
  }

  h4 {
    margin-bottom: 0.7em;
  }

  h5 {
    margin-bottom: 0.3em;
  }
}

.app-declaration {
  th,
  td {
    font-size: 16px;
    padding: 0 10px;
  }

  td {
    &:first-child {
      width: auto;
    }

    width: 100%;
  }
}
