@use 'settings/vars';
@use 'functions/color' as func;
@use 'mixins/breakpoint';

/* stylelint-disable no-descending-specificity */
.profile-section {
  &__row {
    margin-bottom: 20px;
    position: relative;

    .mat-expansion-panel {
      box-shadow: 0 1px 2px 0 rgb(0 0 0 / 7%);

      &-header-title {
        font-size: 18px;
      }
    }

    .mat-expansion-indicator {
      margin-top: -0.5em;

      &::after {
        color: #c94c88;
        border-width: 0 3px 3px 0;
        padding: 5px;
      }
    }

    &-lg {
      margin-bottom: 35px;
      position: relative;
    }
  }

  &__messages {
    position: relative;
  }

  &__rejection {
    .alert {
      margin-bottom: 35px;
    }
  }

  li {
    margin: 0;
  }

  &__address {
    margin-bottom: 30px;
    padding: 0 20px;

    @include breakpoint.bp(min-width, vars.$large-min) {
      padding: 0 40px;
    }

    span {
      color: black;
      display: block;
      font-size: 14px;
      line-height: 1.71;
    }
  }

  .list {
    .list-inner {
      > .link {
        display: block;

        @include breakpoint.bp(min-width, vars.$small-min) {
          display: inline;
        }
      }
    }
  }

  &__buttons {
    margin-top: 50px;

    &-button {
      padding-left: 10px;
      padding-right: 10px;

      @include breakpoint.bp(max-width, vars.$small-max) {
        margin-bottom: 20px;
        width: 100%;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    [class*='col-md'] {
      margin-bottom: 20px;

      @include breakpoint.bp(min-width, vars.$medium-min) {
        margin: 0;
      }

      &:last-child {
        margin: 0;
      }
    }

    &-explainer {
      margin: auto 20px;
    }
  }

  &__numbered-list {
    $size: 26px;

    counter-reset: counter;
    padding-left: $size;

    & > li {
      counter-increment: counter;
      position: relative;
      margin-left: $size;
      margin-bottom: 20px;
      color: func.color(greys, text);
      font-size: 18px;
      line-height: 1.33;

      .title {
        color: func.color(primary, black);
        display: block;
        font-size: 22px;
        line-height: 1.18;
        margin-bottom: 10px;
      }

      &::before {
        content: counter(counter);
        color: func.color(primary, white);
        font-weight: bold;
        position: absolute;
        left: calc(-1 * #{$size} - 16px);
        line-height: $size;
        width: $size;
        height: $size;
        top: 0;
        background: func.color(pinks, 80%);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        text-align: center;
        font-family: proxima-nova, helvetica, arial, sans-serif;
        font-size: 18px;
      }
    }

    &.disclosure {
      margin: 0;
    }
  }

  &__group-list {
    &-title {
      border-bottom: 1px solid func.color(greys, table);
      padding: 15px;

      &.desc {
        h2 {
          margin-bottom: 15px;
        }

        p,
        ul,
        li {
          color: func.color(greys, text);
        }

        ul {
          margin: 0 0 0 1.5em;
        }
      }

      &.border {
        border-bottom: 1px solid func.color(greys, table);
      }

      @include breakpoint.bp(min-width, vars.$medium-min) {
        border: 0;
        padding: 30px;
      }

      &-description {
        margin: 15px 0;
      }
    }

    &-checkbox {
      padding: 30px;
      margin-top: -60px;

      label {
        margin-bottom: 0;
      }
    }

    &-header {
      border-bottom: 1px solid func.color(greys, table);
      display: none;
      padding: 0 30px;

      @include breakpoint.bp(min-width, vars.$medium-min) {
        display: block;
      }

      span {
        display: block;
        padding: 20px 0;
        text-transform: uppercase;
      }
    }

    &-add {
      padding: 15px;

      @include breakpoint.bp(min-width, vars.$medium-min) {
        padding: 20px 30px;
      }

      span {
        color: func.color(blues, text);
        cursor: pointer;
        font-size: 18px;
      }

      .icon-plus {
        svg {
          bottom: -1px;
          margin-right: 15px;
          stroke: func.color(blues, text);
        }
      }
    }

    &-item {
      border-bottom: 1px solid func.color(greys, table);
      padding: 15px;

      @include breakpoint.bp(min-width, vars.$medium-min) {
        padding: 0 30px;
      }

      &-label {
        @include breakpoint.bp(min-width, vars.$medium-min) {
          align-items: center;
          display: flex;
          height: 100%;
          padding: 30px 0;
        }

        span {
          display: block;
          word-break: break-word;
        }

        .note {
          color: func.color(greys, text);
          display: inline;
        }

        &.grey {
          color: func.color(greys, text);
        }

        &.note {
          color: #b8b8b8;
        }
      }

      &-status {
        padding-top: 20px;

        @include breakpoint.bp(min-width, vars.$medium-min) {
          align-items: center;
          display: flex;
          height: 100%;
          padding: 30px 0;
        }

        .item-status {
          align-items: center;
          display: flex;
          flex-flow: row wrap;

          .last-sent {
            color: func.color(greys, text);
            font-size: 14px;
            line-height: 1;
          }

          &-inner {
            display: flex;
            flex-flow: column wrap;
            width: calc(100% - 29px);
          }

          span {
            line-height: 1.1;
          }

          &.incomplete {
            &::before {
              color: #ffb946;
            }
          }

          &.pending {
            &::before {
              color: #b1ddff;
            }
          }

          &.rejected {
            &::before {
              color: #ff3f3f;
            }
          }

          &.complete {
            &::before {
              color: #2ed47a;
            }
          }

          &.download::before {
            color: #847d77;
          }

          &.upload::before {
            color: #004f54;
          }

          &::before {
            content: ' \25cf';
            display: inline-block;
            line-height: 14px;
            font-size: 33px;
            height: 17px;
            margin-right: 10px;
            position: relative;
            width: 19px;
          }
        }
      }

      &-actions {
        align-items: center;
        height: 100%;
        justify-content: space-between;
        padding-top: 20px;

        @include breakpoint.bp(min-width, vars.$medium-min) {
          padding: 30px 0;
        }

        .action {
          padding-left: 10px;
          padding-right: 10px;
        }
      }

      &-comment {
        @include breakpoint.bp(min-width, vars.$medium-min) {
          align-items: center;
          display: flex;
          height: 100%;
          padding: 0 0 30px;
        }

        p {
          color: func.color(reds, error);
          font-weight: vars.$font-weight-semibold;
        }
      }
    }
  }

  &__block {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 7%);

    &-title {
      &-desc {
        margin-bottom: 30px;

        h2 {
          margin-bottom: 15px;
        }

        p {
          color: func.color(greys, text);
        }
      }
    }

    .block {
      margin-top: 30px;

      &-label {
        display: block;
        margin-bottom: 10px;
      }
    }

    &.item {
      padding: 30px;
    }

    .assessment {
      .icon-cross-circle,
      .icon-tick {
        bottom: -6px;
        margin-right: 10px;
      }

      .icon-eye {
        bottom: -1px;
        margin-right: 7px;
      }

      .icon-tick {
        fill: #2ed47a;
      }
    }
  }
}
