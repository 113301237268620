@use 'functions/color' as func;

.pagination {
  &-container {
    border-bottom: 1px solid func.color(primary, base);

    &.bottom {
      border-bottom: 0;
      border-top: 1px solid func.color(primary, base);
    }

    mat-select {
      .mat-select-arrow {
        &::after {
          color: func.color(primary, base);
        }
      }

      .mat-select-value-text {
        color: func.color(primary, base);
      }
    }
  }

  &-selector {
    .numbers-container {
      .numbers {
        span {
          color: func.color(primary, base);
        }
      }
    }
  }
}
