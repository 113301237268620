@use 'sass:list';

/* stylelint-disable scss/dollar-variable-pattern */
$palette: (
  primary: (
    base: #002f49,
    grey: #86786f,
    white: #fff,
    black: #000,
    link: #0076dc,
    pink: #b93b71,
    error: #ff3f3f,
  ),
  secondary: (
    blue: #00aeef,
    warmblue: #0076be,
    grey: #655c55,
    teal: #004f54,
    purple: #4a3650,
    green: #006736,
  ),
  reds: (
    base: #f00,
    error: #ff2e2e,
  ),
  greys: (
    100%: #868990,
    80%: #9ea1a6,
    60%: #b6b8bc,
    40%: #cfd0d3,
    20%: #e7e7e9,
    10%: #f2f3f3,
    border: #585858,
    table: #d6d6d6,
    material: rgb(0 0 0 / 12%),
    border-light: rgb(0 0 0 / 16%),
    border-dark: #666,
    text: #898989,
    light: #f6f6f6,
    disabled: #999,
    body: #787b8c,
    module: #d9d9d9,
    module-bg: #f8f8f8,
    background: #f5f6f8,
    progress: #cbcbcb,
    divider: #ebebeb,
  ),
  borders: (
    primary: #979797,
    base: #ccc,
    focus: #585858,
    active: #c77da0,
    disabled: #c7c6cb,
    error: #a53238,
    set: #c7c7c7,
  ),
  brand: (
    100%: #002f49,
    40%: rgb(0 47 73 / 40%),
    30%: rgb(0 47 73 / 30%),
    20%: rgb(0 47 73 / 20%),
    10%: rgb(0 47 73 / 10%),
  ),
  pinks: (
    100%: #b93b71,
    80%: #c94c88,
    40%: rgb(185 59 113 / 40%),
    30%: rgb(185 59 113 / 30%),
    20%: rgb(185 59 113 / 20%),
    10%: rgb(185 59 113 / 10%),
  ),
  greens: (
    base: #2ed47a,
    success: #40d441,
  ),
  blues: (
    100%: #002855,
    80%: #33567c,
    60%: #66809d,
    40%: #99abbd,
    20%: #ccd5de,
    10%: #e5e9ee,
    info-bg: #e0f6ff,
    info-border: #185479,
    base: #109cf1,
    text: #0076be,
    dark: #334d6e,
    light: #003653,
  ),
  state: (
    default: #002f49,
    complete: #2ed47a,
    incomplete: #ffb946,
    call-us: #ffb946,
    manage: #ffb946,
    issue: #ff3f3f,
    review: #b1ddff,
    pending: #ffb946,
  ),
  status: (
    complete: #23a95f,
    pending: #ffc140,
    error: #c00,
    progress: #f97f55,
  ),
  disabled: (
    base: rgb(0 0 0 / 50%),
  ),
);
$palette-c94c88: rgb(249 234 241) rgb(239 201 219) rgb(228 166 196)
  rgb(217 130 172) rgb(209 103 154) rgb(201 76 136) rgb(195 69 128)
  rgb(188 60 117) rgb(181 51 107) rgb(169 36 88) rgb(255 229 239)
  rgb(255 179 207) rgb(255 128 174) rgb(255 102 158);
$palette-c94c88-50: list.nth($palette-c94c88, 1);
$palette-c94c88-100: list.nth($palette-c94c88, 2);
$palette-c94c88-200: list.nth($palette-c94c88, 3);
$palette-c94c88-300: list.nth($palette-c94c88, 4);
$palette-c94c88-400: list.nth($palette-c94c88, 5);
$palette-c94c88-500: list.nth($palette-c94c88, 6);
$palette-c94c88-600: list.nth($palette-c94c88, 7);
$palette-c94c88-700: list.nth($palette-c94c88, 8);
$palette-c94c88-800: list.nth($palette-c94c88, 9);
$palette-c94c88-900: list.nth($palette-c94c88, 10);
$palette-c94c88-A100: list.nth($palette-c94c88, 11);
$palette-c94c88-A200: list.nth($palette-c94c88, 12);
$palette-c94c88-A400: list.nth($palette-c94c88, 13);
$palette-c94c88-A700: list.nth($palette-c94c88, 14);
$palette-002f49: rgb(224 230 233) rgb(179 193 200) rgb(128 151 164)
  rgb(77 109 128) rgb(38 78 100) rgb(0 47 73) rgb(0 42 66) rgb(0 35 57)
  rgb(0 29 49) rgb(0 18 33) rgb(93 158 255) rgb(42 127 255) rgb(0 99 246)
  rgb(0 88 221);
$palette-002f49-50: list.nth($palette-002f49, 1);
$palette-002f49-100: list.nth($palette-002f49, 2);
$palette-002f49-200: list.nth($palette-002f49, 3);
$palette-002f49-300: list.nth($palette-002f49, 4);
$palette-002f49-400: list.nth($palette-002f49, 5);
$palette-002f49-500: list.nth($palette-002f49, 6);
$palette-002f49-600: list.nth($palette-002f49, 7);
$palette-002f49-700: list.nth($palette-002f49, 8);
$palette-002f49-800: list.nth($palette-002f49, 9);
$palette-002f49-900: list.nth($palette-002f49, 10);
$palette-002f49-A100: list.nth($palette-002f49, 11);
$palette-002f49-A200: list.nth($palette-002f49, 12);
$palette-002f49-A400: list.nth($palette-002f49, 13);
$palette-002f49-A700: list.nth($palette-002f49, 14);

/* For use in src/lib/core/theming/_palette.scss */
$md-primary: (
  50: #f9eaf1,
  100: #efc9db,
  200: #e4a6c4,
  300: #d982ac,
  400: #d1679a,
  500: #c94c88,
  600: #c34580,
  700: #bc3c75,
  800: #b5336b,
  900: #a92458,
  A100: #ffe5ef,
  A200: #ffb3cf,
  A400: #ff80ae,
  A700: #ff669e,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);
