@use 'sass:color';

@mixin alert-variant($background, $border-color, $border-width, $text-color) {
  background-color: $background;
  border: $border-width solid $border-color;
  border-radius: 6px;
  color: $text-color;

  hr {
    border-top-color: color.adjust($border-color, $lightness: -5%);
  }

  .alert-link {
    color: color.adjust($text-color, $lightness: -10%);

    &--blue {
      color: #109cf1;
      font-weight: bold;
      text-decoration: underline;
    }
  }
}
