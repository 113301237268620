@use 'functions/color' as func;

/* stylelint-disable no-descending-specificity */

/* stylelint-disable selector-class-pattern */
.input-container {
  position: relative;

  &.disabled {
    .input-label {
      user-select: none;
    }

    .input,
    .mat-input-element {
      color: func.color(disabled, base);

      &:hover,
      &:focus {
        border-color: func.color(greys, 100%);
      }
    }
  }
}

.input-label {
  position: relative;
}

.input-box {
  max-width: 600px;

  .input,
  .mat-input-element {
    border: 1px solid func.color(greys, border);
    border-radius: 5px;
    color: func.color(primary, black);
    transition: border-color 0.3s ease-in-out;

    &:hover,
    &:focus {
      border-color: func.color(primary, base);
    }
  }

  .placeholder {
    background-color: func.color(primary, white);
  }

  .mat-form-field-placeholder {
    color: func.color(primary, grey);
  }
}
